/** @format */

import config from 'config';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import PublicRoute from 'components/RoutedComponent/PublicRoute';
import PrivateRoute from 'components/RoutedComponent/PrivateRoute';

const RoutedComponent = (props) => {
  const { t } = useTranslation();
  const { flow, view, path, isPrivateRoute, Component } = props;
  const seoPageTitle = t(`lang_common:seo.title.${view}.text`);
  const RouteType = !isPrivateRoute ? PublicRoute : PrivateRoute;

  const [getWindowWidth, setWindowWidth] = React.useState(0);

  /** scroll window o the top for all views  */
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  React.useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  const isMobileView =
    getWindowWidth <= config.app.mobileScreenMaxWidth || isMobile;

  if (!path) {
    return (
      <Redirect
        to={`${config.app.defaultRoute}?error=path-given-in-routes-is-unreachable`}
      />
    );
  }
  return (
    <>
      <Helmet>
        <title>{seoPageTitle}</title>
      </Helmet>
      <RouteType {...props}>
        <Component
          {...props}
          tprop={t(`lang_${flow}:${view}`)}
          t={t}
          isMobileView={isMobileView}
        />
      </RouteType>
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return nextProps === prevProps;
};

export default React.memo(RoutedComponent, areEqual);
