/** @format */

import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import ButtonSyncLoader from 'components/ButtonSyncLoader';

export default function CommonModal({
  showModal,
  handleClose,
  header,
  body,
  body_next_line,
  button_1,
  button_2,
  handleYes,
  yesResponsePending
}) {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className='text1 pink-text'>{header}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='text2r'>{body}</p>
        <p className='text2r'>{body_next_line}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className='modal-button-container'>
          <Button
            className='btn btn-pink modal-fullwidth-button'
            onClick={handleYes}
            disabled={yesResponsePending}>
            {yesResponsePending ? <ButtonSyncLoader /> : button_1}
          </Button>
          <Button
            className='btn btn-white modal-fullwidth-button modal-second-button'
            onClick={handleClose}>
            {button_2}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
