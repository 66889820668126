/** @format */

import axios from 'axios';

import apiConfig from 'config/app';
import { store } from 'stateContainer/store';
/**
 * Common function to call Fria Maklare API endpoints
 *
 * @param url - Api endpoint to call ex:- healthcheck, registerBroker etc
 * @param httpType - API endpoint HTTP type to use ex:- GET, POST, PUT, DELETE
 * @param data - data object to be passed to the API
 * @param auth -boolean- whether auth params to be included or not - default false
 * @param requestContentType - Request content type ex:- application/json, multipart/formdata etc
 *        default is application/json
 *
 * @returns Object
 *
 * @author Sandun Munasinghe
 * @since 16-08-2022
 */
const callFriaMaklareApi = async (
  endpointUrl,
  httpType = 'GET',
  data = null,
  auth = false,
  requestContentType = 'application/json'
) => {
  let requestParamsObj = {
    timeout: 10000
  };

  const { session } = store.getState();

  const url = `${apiConfig.baseApiUrl}/${endpointUrl}`;
  const accessToken = session.data.accessToken;

  if (httpType == 'GET' || httpType == 'DELETE') {
    if (data) {
      requestParamsObj.params = data;
    }

    if (auth) {
      requestParamsObj.headers = {
        Authorization: `Bearer ${accessToken}`
      };
    }
    if (httpType == 'GET') {
      return axios.get(url, requestParamsObj);
    } else {
      return axios.delete(url, requestParamsObj);
    }
  } else if (httpType == 'POST' || httpType == 'PUT') {
    let postRequestObj = {
      method: httpType.toLowerCase(),
      url,
      headers: {
        'Content-Type': requestContentType
      }
    };
    if (data) {
      postRequestObj.data = data;
    }

    if (auth) {
      postRequestObj.headers = {
        ...postRequestObj.headers,
        Authorization: `Bearer ${accessToken}`
      };
    }

    return axios(postRequestObj);
  }
};

/**
 *
 * Calls the admin login API in Fria Maklare
 *
 * @param {*} Object {
 *    firstName
 *    lastname
 *    email
 * }
 *
 * @author Sandun Munasinghe
 * @since 16-08-2022
 */
export const adminloginApiService = async ({ username, password }) => {
  const response = await callFriaMaklareApi('adminLogin', 'POST', {
    username,
    password
  });
  return response;
};

/**
 *
 * Calls the admin logout API in Fria Maklare
 *
 * @param {*} Object {
 *    refreshToken
 * }
 *
 * @author Sandun Munasinghe
 * @since 16-08-2022
 */
export const adminLogoutApiService = async ({ refreshToken }) => {
  const response = await callFriaMaklareApi(
    'adminLogout',
    'POST',
    {
      refreshToken
    },
    true
  );
  return response;
};
/**
 *
 * Calls get pre onboarded brokers list API in Fria Maklare
 *
 * @param {*} Object {
 *    page,
 *    recordsPerPage
 * }
 *
 * @author Sandun Munasinghe
 * @since 16-08-2022
 */
export const getPreOnboardedBrokerListApiService = async (params) => {
  const response = await callFriaMaklareApi(
    'listOnboardedBrokers/pre',
    'GET',
    params,
    true
  );
  return response;
};
/**
 *
 * Calls get post onboarded brokers list API in Fria Maklare
 *
 * @param {*} Object {
 *    page,
 *    recordsPerPage
 * }
 *
 * @author Sandun Munasinghe
 * @since 16-08-2022
 */
export const getPostOnboardedBrokerListApiService = async (params) => {
  const response = await callFriaMaklareApi(
    'listOnboardedBrokers/post',
    'GET',
    params,
    true
  );
  return response;
};

/**
 *
 * Calls pre onboarded brokers approve API in Fria Maklare
 *
 * @param {*} Object {
 * brokerId
 * }
 *
 * @author Sandun Munasinghe
 * @since 24-08-2022
 */
export const approvePreOnboardedBrokerApiService = async ({ brokerId }) => {
  const response = await callFriaMaklareApi(
    'preOnboardingRequest/approve',
    'PUT',
    {
      brokerId
    },
    true
  );
  return response;
};

/**
 *
 * Calls pre onboarded brokers decline API in Fria Maklare
 *
 * @param {*} Object {
 * brokerId
 * }
 *
 * @author Sandun Munasinghe
 * @since 24-08-2022
 */
export const declinePreOnboardedBrokerApiService = async ({ brokerId }) => {
  const response = await callFriaMaklareApi(
    'preOnboardingRequest/decline',
    'PUT',
    {
      brokerId
    },
    true
  );
  return response;
};

/**
 *
 * Calls post onboarded brokers decline API in Fria Maklare
 *
 * @param {*} Object {
 * brokerId
 * }
 *
 * @author Sandun Munasinghe
 * @since 24-08-2022
 */
export const declinePostOnboardedBrokerApiService = async ({ brokerId }) => {
  const response = await callFriaMaklareApi(
    'postOnboardingRequest/decline',
    'PUT',
    {
      brokerId
    },
    true
  );
  return response;
};

/**
 *
 * Calls create broker API in Fria Maklare
 *
 * @param {*} Object {
 * brokerId
 * tempPassword
 * }
 *
 * @author Sandun Munasinghe
 * @since 25-08-2022
 */
export const createPostOnboardedBrokerApiService = async ({
  brokerId,
  tempPassword,
  fmEmail
}) => {
  const response = await callFriaMaklareApi(
    'createBrokerUser',
    'POST',
    {
      brokerId,
      tempPassword,
      fmEmail
    },
    true
  );
  return response;
};

/**
 *
 * Calls list all users API in Fria Maklare
 *
 * @param {*} Object {
 * name,
 * page,
 * recordsPerPage
 * }
 *
 * @author Sandun Munasinghe
 * @since 26-08-2022
 */
export const listAllBrokersApiService = async (params) => {
  const response = await callFriaMaklareApi(
    'listAllBrokers',
    'GET',
    params,
    true
  );
  return response;
};

/**
 *
 * Calls activate broker API in Fria Maklare
 *
 * @param {*} Object {
 * brokerId
 * }
 *
 * @author Sandun Munasinghe
 * @since 26-08-2022
 */
export const activateBrokerApiService = async ({ brokerId }) => {
  const response = await callFriaMaklareApi(
    'broker/activate',
    'PUT',
    {
      brokerId
    },
    true
  );
  return response;
};

/**
 *
 * Calls deactivate broker API in Fria Maklare
 *
 * @param {*} Object {
 * brokerId
 * }
 *
 * @author Sandun Munasinghe
 * @since 26-08-2022
 */
export const deactivateBrokerApiService = async ({ brokerId }) => {
  const response = await callFriaMaklareApi(
    'broker/deactivate',
    'PUT',
    {
      brokerId
    },
    true
  );
  return response;
};

/**
 *
 * Calls list all leads API in Fria Maklare
 *
 * @param {*} Object {
 * name,
 * page,
 * recordsPerPage
 * }
 *
 * @author Daniel A
 * @since 20-10-2024
 */
export const listAllLeadsApiService = async (params) => {
  const response = await callFriaMaklareApi('leads', 'GET', params, true);
  return response;
};

/**
 *
 * Calls list all broker leads API in Fria Maklare
 *
 * @param {*} Object {
 * name,
 * page,
 * recordsPerPage
 * }
 *
 * @author Daniel A
 * @since 3-11-2024
 */
export const listAllBrokerLeadsApiService = async (params) => {
  const response = await callFriaMaklareApi('brokerLeads', 'GET', params, true);
  return response;
};
