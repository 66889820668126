/** @format */

/** Login action-types **/
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

/** Logout action-types **/
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

/** subscription related actions */

export const ACTIVE_INDEX = 'ACTIVE_INDEX';

/** get pre onboarded brokers list action */
export const PRE_ONBOARDED_BROKERS_LIST_REQUESTED =
  'PRE_ONBOARDED_BROKERS_LIST_REQUESTED';
export const PRE_ONBOARDED_BROKERS_LIST_SUCCESS =
  'PRE_ONBOARDED_BROKERS_LIST_SUCCESS';
export const PRE_ONBOARDED_BROKERS_LIST_FAILED =
  'PRE_ONBOARDED_BROKERS_LIST_FAILED';

/** decline pre onboarded broker action */
export const DECLINE_PRE_ONBOARDED_BROKERS_REQUESTED =
  'DECLINE_PRE_ONBOARDED_BROKERS_REQUESTED';
export const DECLINE_PRE_ONBOARDED_BROKERS_SUCCESS =
  'DECLINE_PRE_ONBOARDED_BROKERS_SUCCESS';
export const DECLINE_PRE_ONBOARDED_BROKERS_FAILED =
  'DECLINE_PRE_ONBOARDED_BROKERS_FAILED';

/** approve pre onboarded broker action */
export const APPROVE_PRE_ONBOARDED_BROKERS_REQUESTED =
  'APPROVE_PRE_ONBOARDED_BROKERS_REQUESTED';
export const APPROVE_PRE_ONBOARDED_BROKERS_SUCCESS =
  'APPROVE_PRE_ONBOARDED_BROKERS_SUCCESS';
export const APPROVE_PRE_ONBOARDED_BROKERS_FAILED =
  'APPROVE_PRE_ONBOARDED_BROKERS_FAILED';

/** get post onboarded brokers list action */
export const POST_ONBOARDED_BROKERS_LIST_REQUESTED =
  'POST_ONBOARDED_BROKERS_LIST_REQUESTED';
export const POST_ONBOARDED_BROKERS_LIST_SUCCESS =
  'POST_ONBOARDED_BROKERS_LIST_SUCCESS';
export const POST_ONBOARDED_BROKERS_LIST_FAILED =
  'POST_ONBOARDED_BROKERS_LIST_FAILED';

/** decline post onboarded broker action */
export const DECLINE_POST_ONBOARDED_BROKER_REQUESTED =
  'DECLINE_POST_ONBOARDED_BROKER_REQUESTED';
export const DECLINE_POST_ONBOARDED_BROKER_SUCCESS =
  'DECLINE_POST_ONBOARDED_BROKER_SUCCESS';
export const DECLINE_POST_ONBOARDED_BROKER_FAILED =
  'DECLINE_POST_ONBOARDED_BROKER_FAILED';

/** create post onboarded broker action */
export const CREATE_POST_ONBOARDED_BROKER_REQUESTED =
  'CREATE_POST_ONBOARDED_BROKER_REQUESTED';
export const CREATE_POST_ONBOARDED_BROKER_SUCCESS =
  'CREATE_POST_ONBOARDED_BROKER_SUCCESS';
export const CREATE_POST_ONBOARDED_BROKER_FAILED =
  'CREATE_POST_ONBOARDED_BROKER_FAILED';

/** Reset stores */
export const RESET_POST_ONBOARDED_BROKERS = 'RESET_POST_ONBOARDED_BROKERS';
export const RESET_PRE_ONBOARDED_BROKERS = 'RESET_PRE_ONBOARDED_BROKERS';
export const RESET_MESSAGE_STATE = 'RESET_MESSAGE_STATE';

/** list all brokers actions */
export const All_BROKERS_LIST_REQUESTED = 'All_BROKERS_LIST_REQUESTED';
export const All_BROKERS_LIST_SUCCESS = 'All_BROKERS_LIST_SUCCESS';
export const All_BROKERS_LIST_FAILED = 'All_BROKERS_LIST_FAILED';

/** activate broker actions */
export const ACTIVATE_BROKER_REQUESTED = 'ACTIVATE_BROKER_REQUESTED';
export const ACTIVATE_BROKER_SUCCESS = 'ACTIVATE_BROKER_SUCCESS';
export const ACTIVATE_BROKER_FAILED = 'ACTIVATE_BROKER_FAILED';

/** deactivate broker actions */
export const DEACTIVATE_BROKER_REQUESTED = 'DEACTIVATE_BROKER_REQUESTED';
export const DEACTIVATE_BROKER_SUCCESS = 'DEACTIVATE_BROKER_SUCCESS';
export const DEACTIVATE_BROKER_FAILED = 'DEACTIVATE_BROKER_FAILED';

/** list leads actions */
export const All_LEADS_LIST_REQUESTED = 'All_LEADS_LIST_REQUESTED';
export const All_LEADS_LIST_SUCCESS = 'All_LEADS_LIST_SUCCESS';
export const All_LEADS_LIST_FAILED = 'All_LEADS_LIST_FAILED';

/** list broker leads actions */
export const All_BROKER_LEADS_LIST_REQUESTED =
  'All_BROKER_LEADS_LIST_REQUESTED';
export const All_BROKER_LEADS_LIST_SUCCESS = 'All_BROKER_LEADS_LIST_SUCCESS';
export const All_BROKER_LEADS_LIST_FAILED = 'All_BROKER_LEADS_LIST_FAILED';
