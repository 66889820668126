/** @format */

import React from 'react';
import config from 'config';
import { routeService } from 'services';
import { Switch, Route } from 'react-router-dom';
import RoutedComponent from 'components/RoutedComponent';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

/** Containers sorted according to the registration flow order */

/**
 * Common views
 */
import Login from 'views/Admin/Login';
import PageNotFound from 'views/Static/PageNotFound';
import Dashboard from 'views/Admin/Dashboard';

const {
  constants: { flows, views, flowOrigins }
} = config;

const commonProps = {
  flow: flows.admin,
  routeService: routeService,
  flowOrigin: flowOrigins.admin
};

export default function Routes() {
  const { t } = useTranslation();
  const seoCommonTitle = t('lang_common:seo.title.common.text');
  const seoDescription = t('lang_common:seo.title.common.description.text');
  return (
    <>
      <Helmet>
        <title>{seoCommonTitle} </title>
        <meta name='description' content={seoDescription} />
      </Helmet>
      <Switch>
        {/*
         * Beginning of registration flow
         */}

        <RoutedComponent /*** Root page ***/
          exact={true}
          isPrivateRoute={false}
          Component={Login}
          view={views.login}
          path={routeService.root}
          {...commonProps}
        />

        <RoutedComponent /*** Login page ***/
          exact={true}
          isPrivateRoute={false}
          Component={Login}
          view={views.login}
          path={routeService.admin_login}
          {...commonProps}
        />

        <RoutedComponent /*** Dashboard ***/
          exact={true}
          isPrivateRoute={true}
          Component={Dashboard}
          view={views.dashboard}
          path={routeService.admin_dashboard}
          {...commonProps}
        />

        <Route>
          <PageNotFound
            flow={flows.common}
            view={views.page_not_found}
            routeService={routeService}
          />
        </Route>
      </Switch>
    </>
  );
}
