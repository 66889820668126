/** @format */

import { push } from 'connected-react-router';
import { listAllLeadsApiService } from 'services/apiService';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const response = yield call(listAllLeadsApiService, payload);
    if (response.data.status == 'success') {
      /**
       * finally conclude the list action
       */

      yield put({
        type: actionTypes.All_LEADS_LIST_SUCCESS,
        data: response.data.data,
        paging: response.data.paging
      });
    } else {
      throw new Error('Data fetch failed');
    }
  } catch (error) {
    if (error.response.data.error == 'NotAuthorized') {
      yield put(push({ type: actionTypes.LOGOUT_REQUESTED }));
      yield put(push('/login'));
    }
    yield put({ type: actionTypes.All_LEADS_LIST_FAILED, error });
  }
}

export function* fetchAllLeadsSaga() {
  yield takeLatest(actionTypes.All_LEADS_LIST_REQUESTED, workerSaga);
}
