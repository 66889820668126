/** @format */

import React, { useState, useEffect } from 'react';

import { Table, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import BrokerDetail from './BrokerDetail';
import Pagination from '../Pagination';
import CommonModal from 'components/Modals/CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPostOnboardedBrokerListRequestedAction,
  declinePostOnboardedBrokerRequestedAction,
  createPostOnboardedBrokerRequestedAction
} from 'stateContainer/actions';
import TableDataLoader from 'components/TableDataLoader';

export default function PostOnboarding({
  text: {
    heading,
    button_decline,
    declineModal: { header, body, body_next_line, button_1, button_2 },
    table: { headings }
  },
  brokerDetailText
}) {
  const dispatch = useDispatch();
  const postOnboardedBrokersData = useSelector(
    (state) => state.postOnboardedBrokersReducer
  );

  const activeNav = useSelector((state) => state.activeNav);
  const { logoutInitiated } = useSelector((state) => state.session);

  const [showModal, setShowModal] = useState(false);
  const [selectedBrokerDetails, setSelectedBrokerDetails] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClose = () => setShowModal(false);
  const handleShow = (brokerId) => setShowModal({ brokerId });

  useEffect(() => {
    handleClose();
    if (!postOnboardedBrokersData.listRequesting && !logoutInitiated) {
      dispatch(
        getPostOnboardedBrokerListRequestedAction({
          page: 1,
          recordsPerPage: 5
        })
      );
    }

    if (postOnboardedBrokersData.createSuccess) {
      setSelectedBrokerDetails(null);
    }
  }, [
    postOnboardedBrokersData.createSuccess,
    postOnboardedBrokersData.declineSuccess,
    activeNav.index
  ]);

  const handleDeclineBroker = () => {
    const brokerId = showModal.brokerId;
    dispatch(
      declinePostOnboardedBrokerRequestedAction({
        brokerId
      })
    );
  };
  const handleCreateBroker = ({ brokerId, tempPassword, fmEmail }) => {
    dispatch(
      createPostOnboardedBrokerRequestedAction({
        brokerId,
        tempPassword,
        fmEmail
      })
    );
  };

  const getTableRowNumber = (dataIndex) => {
    const recordsPerpage = postOnboardedBrokersData.paging.recordsPerPage;
    const currentpage = postOnboardedBrokersData.paging.page;

    return (currentpage - 1) * recordsPerpage + (dataIndex + 1);
  };

  const paginationHandler = (pageNumber) => {
    dispatch(
      getPostOnboardedBrokerListRequestedAction({
        page: pageNumber,
        recordsPerPage: 5
      })
    );
  };

  const setRowActive = ({ row, index }) => {
    setSelectedIndex(index);
    setSelectedBrokerDetails(row);
  };

  return (
    <div className='dashboard-body-section-2'>
      <div className='dashboard-body-section-2-inner'>
        <div className='dashboard-inner-section-1'>
          <div className='dashboard-main-heading'>
            <h2>{heading}</h2>
          </div>
          <div>
            {postOnboardedBrokersData.listRequesting && <TableDataLoader />}
            {!postOnboardedBrokersData.listRequesting && (
              <Table bordered className='dashboard-preonboarding-table' hover>
                <thead>
                  <tr>
                    {headings.map((heading, index) => (
                      <th key={index}>{heading}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {postOnboardedBrokersData.data &&
                    postOnboardedBrokersData.data.map((row, index) => (
                      <tr
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setRowActive({ row, index });
                        }}
                        className={
                          selectedIndex === index ? 'selected-row' : ''
                        }>
                        <td>{getTableRowNumber(index)}</td>
                        <td>{row.firstName + ' ' + row.lastName}</td>
                        <td>{row.phoneNumber}</td>
                        <td>{row.socialSecurityNumber}</td>
                        <td>
                          <Button
                            className={'btn btn-white'}
                            onClick={() => handleShow(row.brokerId)}>
                            <FontAwesomeIcon
                              icon={faXmarkCircle}
                              size='lg'
                              color='#7A7A7A'
                            />
                            {button_decline}
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </div>
          <Pagination
            paging={postOnboardedBrokersData.paging}
            paginationHandler={paginationHandler}
            getFirstRecordNumber={getTableRowNumber}
            dataLoading={postOnboardedBrokersData.listRequesting}
          />
        </div>

        <BrokerDetail
          text={brokerDetailText}
          selectedBrokerDetails={selectedBrokerDetails}
          handleCreateBroker={handleCreateBroker}
        />
      </div>
      <CommonModal
        handleClose={handleClose}
        showModal={showModal}
        header={header}
        body={body}
        body_next_line={body_next_line}
        button_1={button_1}
        button_2={button_2}
        handleYes={handleDeclineBroker}
        yesResponsePending={postOnboardedBrokersData.declineRequesting}
      />
    </div>
  );
}
