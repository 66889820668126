/** @format */

import React, { useEffect, useState } from 'react';
import { Table, Button, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXmarkCircle,
  faCircleCheck,
  faSearch,
  faCopy
} from '@fortawesome/free-solid-svg-icons';
import Pagination from '../Pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  listAllBrokersRequestedAction,
  activateBrokerRequestedAction,
  deactivateBrokerRequestedAction
} from 'stateContainer/actions';
import moment from 'moment';
import TableDataLoader from 'components/TableDataLoader';
import ButtonSyncLoader from 'components/ButtonSyncLoader';
import appConfig from 'config/app';

const recordsPerPage = 8;

export default function AllBrokers({
  text: {
    heading,
    table: { table_headings }
  },
  handleCopyLinkToClipBoard
}) {
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    dispatch(
      listAllBrokersRequestedAction({
        page: 1,
        recordsPerPage
      })
    );
  }, []);

  const allBrokersData = useSelector((state) => state.allBrokers);

  const dispatch = useDispatch();

  const getTableRowNumber = (dataIndex) => {
    const recordsPerpage = allBrokersData.paging.recordsPerPage;
    const currentpage = allBrokersData.paging.page;

    return (currentpage - 1) * recordsPerpage + (dataIndex + 1);
  };

  const paginationHandler = (pageNumber) => {
    let params = {
      page: pageNumber,
      recordsPerPage
    };
    if (searchName != '') {
      params.name = searchName;
    }
    dispatch(listAllBrokersRequestedAction(params));
  };

  const activateDeactivateBrokerHandler = (brokerId, action = 'activate') => {
    if (action == 'activate') {
      dispatch(activateBrokerRequestedAction({ brokerId }));
    } else {
      dispatch(deactivateBrokerRequestedAction({ brokerId }));
    }
  };

  const searchBrokersHandler = (event) => {
    event.preventDefault();
    let params = {
      page: 1,
      recordsPerPage
    };
    if (searchName != '') {
      params.name = searchName;
    }
    dispatch(listAllBrokersRequestedAction(params));
  };

  return (
    <div className='allbrokers-container'>
      <div>
        <div className='allbrokers-heading'>
          <h2>{heading}</h2>
          <Form
            className='allbrokers-search-field'
            onSubmit={searchBrokersHandler}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon
                  icon={faSearch}
                  color='var(--color-neutral-dark-600)'
                  style={{ cursor: 'pointer' }}
                  onClick={searchBrokersHandler}
                />
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Search'
                onChange={(event) => {
                  setSearchName(event.target.value);
                }}
              />
            </InputGroup>
          </Form>
        </div>
        {allBrokersData.listRequesting && <TableDataLoader />}
        {!allBrokersData.listRequesting && (
          <Table bordered className='allbrokers-table' hover>
            <thead>
              <tr>
                {table_headings.map((table_heading, index) => (
                  <th key={index}>{table_heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {allBrokersData.data.map((row, index) => (
                <tr key={index}>
                  <td>{getTableRowNumber(index)}</td>
                  <td>{row.firstName + ' ' + row.lastName}</td>
                  <td>
                    {row.dateRegistered
                      ? moment(row.dateRegistered).format(
                          appConfig.defaultMomentDateFormat
                        )
                      : '-'}
                  </td>
                  <td>
                    {row.lastLoggedIn
                      ? moment(row.lastLoggedIn).format(
                          appConfig.defaultMomentDateFormat
                        )
                      : 'Never'}
                  </td>
                  <td>{row.fmEmail}</td>
                  <td>{row.email}</td>
                  <td>{row.socialSecurityNumber}</td>
                  <td>{row.phoneNumber}</td>
                  <td>
                    {row.addressLine1}, {row.addressLine2}, {row.city},{' '}
                    {row.zipCode}
                  </td>
                  <td>
                    {row.brokerStatus == 'active' ? (
                      <div className='allbrokers-table-status-container allbrokers-table-status-container-green'>
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size='sm'
                          color='white'
                        />
                        <p className='text4r'>Active</p>
                      </div>
                    ) : (
                      <div className='allbrokers-table-status-container allbrokers-table-status-container-red'>
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size='sm'
                          color='white'
                        />
                        <p className='text4r'>Inactive</p>
                      </div>
                    )}
                  </td>
                  <td>{row.listedObjects}</td>
                  <td>{row.completedObjects}</td>
                  <td>
                    {row.brokerLicenceExpiryDate
                      ? moment(row.brokerLicenceExpiryDate).format(
                          appConfig.defaultMomentDateFormat
                        )
                      : '-'}
                  </td>
                  <td>
                    {allBrokersData.actionRequestingBrokerId ==
                      row.brokerId && (
                      <Button className={'btn btn-white'} disabled={true}>
                        <ButtonSyncLoader />
                      </Button>
                    )}
                    {row.brokerStatus == 'active' ? (
                      <Button
                        disabled={allBrokersData.activateDeactivateRequesting}
                        className={'btn btn-white'}
                        onClick={() => {
                          activateDeactivateBrokerHandler(
                            row.brokerId,
                            'deactivate'
                          );
                        }}>
                        <FontAwesomeIcon
                          icon={faXmarkCircle}
                          size='lg'
                          color='var(--color-neutral-dark-600)'
                        />
                        Deactivate profile
                      </Button>
                    ) : (
                      <Button
                        disabled={allBrokersData.activateDeactivateRequesting}
                        className={'btn btn-pink'}
                        onClick={() => {
                          activateDeactivateBrokerHandler(
                            row.brokerId,
                            'activate'
                          );
                        }}>
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size='lg'
                          color='white'
                        />
                        Activate profile
                      </Button>
                    )}
                  </td>
                  <td>
                    <Button
                      disabled={allBrokersData.activateDeactivateRequesting}
                      className={'btn btn-pink'}
                      onClick={() => {
                        handleCopyLinkToClipBoard({
                          brokerId: row.brokerId,
                          brokerSlug: row.brokerSlug,
                          destination: 'profile',
                          searchable: row.searchable
                        });
                      }}>
                      <FontAwesomeIcon icon={faCopy} size='lg' color='white' />
                      Copy profile link
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>

      <Pagination
        paging={allBrokersData.paging}
        paginationHandler={paginationHandler}
        getFirstRecordNumber={getTableRowNumber}
        dataLoading={allBrokersData.listRequesting}
      />
    </div>
  );
}
