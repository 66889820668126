/** @format */

import { push } from 'connected-react-router';
import { getPostOnboardedBrokerListApiService } from 'services/apiService';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const response = yield call(getPostOnboardedBrokerListApiService, payload);
    if (response.data.status == 'success') {
      /**
       * finally conclude the login action
       */

      yield put({
        type: actionTypes.POST_ONBOARDED_BROKERS_LIST_SUCCESS,
        data: response.data.data,
        paging: response.data.paging
      });
    } else {
      throw new Error('Data fetch failed');
    }
  } catch (error) {
    if (error.response.data.error == 'NotAuthorized') {
      yield put(push({ type: actionTypes.LOGOUT_REQUESTED }));
      yield put(push('/login'));
    }
    yield put({ type: actionTypes.POST_ONBOARDED_BROKERS_LIST_FAILED, error });
  }
}

export function* fetchPostOnboardedBrokersSaga() {
  yield takeLatest(
    actionTypes.POST_ONBOARDED_BROKERS_LIST_REQUESTED,
    workerSaga
  );
}
