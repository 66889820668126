/** @format */

import React, {
  useEffect
  // useState
} from 'react';
import {
  Table,
  // Button,
  InputGroup
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faXmarkCircle,
  // faCircleCheck,
  faSearch
  // faCopy
} from '@fortawesome/free-solid-svg-icons';
import Pagination from '../Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { listAllBrokerLeadsRequestedAction } from 'stateContainer/actions';
import moment from 'moment';
import TableDataLoader from 'components/TableDataLoader';
// import ButtonSyncLoader from 'components/ButtonSyncLoader';
import appConfig from 'config/app';
import StatusIndicator from 'components/StatusIndicator';

const recordsPerPage = 8;

const LEAD_STATUSES = {
  NEW: 'NEW',
  RESPONDED: 'RESPONDED'
};

export default function BrokerLeads({
  text: {
    heading,
    table: { table_headings }
  }
  // handleCopyLinkToClipBoard
}) {
  // const [searchName, setSearchName] = useState('');

  useEffect(() => {
    dispatch(
      listAllBrokerLeadsRequestedAction({
        page: 1,
        recordsPerPage
      })
    );
  }, []);

  const allLeadsData = useSelector((state) => state.brokerLeads);

  const dispatch = useDispatch();

  const getTableRowNumber = (dataIndex) => {
    const recordsPerpage = allLeadsData.paging.recordsPerPage;
    const currentpage = allLeadsData.paging.page;

    return (currentpage - 1) * recordsPerpage + (dataIndex + 1);
  };

  const paginationHandler = (pageNumber) => {
    let params = {
      page: pageNumber,
      recordsPerPage
    };
    // if (searchName != '') {
    //   params.name = searchName;
    // }
    dispatch(listAllBrokerLeadsRequestedAction(params));
  };

  // const activateDeactivateBrokerHandler = (brokerId, action = 'activate') => {
  //   if (action == 'activate') {
  //     dispatch(activateBrokerRequestedAction({ brokerId }));
  //   } else {
  //     dispatch(deactivateBrokerRequestedAction({ brokerId }));
  //   }
  // };

  const searchBrokersHandler = (event) => {
    event.preventDefault();
    let params = {
      page: 1,
      recordsPerPage
    };
    // if (searchName != '') {
    //   params.name = searchName;
    // }
    dispatch(listAllBrokerLeadsRequestedAction(params));
  };

  const LeadsDataRow = ({ index, dataRow }) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td
          style={{
            textAlign: 'center'
          }}>
          <StatusIndicator
            greenStatus={dataRow.vitec_response_status == 200}
            redStatus={dataRow.vitec_response_status != 200}
            text={dataRow.vitec_response_status == 200 ? 'Success' : 'Failed'}
          />
        </td>

        <td>{dataRow.firstName}</td>
        <td>{dataRow.lastName}</td>
        <td> {dataRow.email}</td>
        <td> {dataRow.socialSecurityNumber}</td>
        <td> {dataRow.contactNo}</td>
        <td> {dataRow.streetAddress}</td>
        <td> {dataRow.zipCode}</td>
        <td> {dataRow.city}</td>
        <td> {dataRow.message}</td>
        <td
          style={{
            textAlign: 'center'
          }}>
          <StatusIndicator
            greenStatus={dataRow.status === LEAD_STATUSES.NEW}
            greyStatus={dataRow.status === LEAD_STATUSES.RESPONDED}
            text={dataRow.status}
          />
        </td>
        <td> {dataRow.customerId}</td>
        <td>
          {dataRow.createdDatetime
            ? moment(dataRow.createdDatetime).format(
                appConfig.defaultMomentDateFormat
              )
            : '-'}
        </td>

        <td>
          {/* <Button className={'btn btn-pink'}>
            <FontAwesomeIcon icon={faCopy} size='lg' color='white' />
            Mark as attended
          </Button> */}
        </td>
      </tr>
    );
  };

  return (
    <div className='broker-leads-container'>
      <div>
        <div className='broker-leads-heading'>
          <h2>{heading}</h2>
          <Form
            className='broker-leads-search-field'
            onSubmit={searchBrokersHandler}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon
                  icon={faSearch}
                  color='var(--color-neutral-dark-600)'
                  style={{ cursor: 'pointer' }}
                  // onClick={searchBrokersHandler}
                />
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Search'
                // onChange={(event) => {
                //   setSearchName(event.target.value);
                // }}
              />
            </InputGroup>
          </Form>
        </div>
        {allLeadsData.listRequesting && <TableDataLoader />}
        {!allLeadsData.listRequesting && (
          <Table bordered className='broker-leads-table' hover>
            <thead>
              <tr>
                {table_headings.map((table_heading, index) => (
                  <th key={index}>{table_heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {allLeadsData.data.map((row, index) => (
                <LeadsDataRow key={index} index={index} dataRow={row} />
              ))}
            </tbody>
          </Table>
        )}
      </div>

      <Pagination
        paging={allLeadsData.paging}
        paginationHandler={paginationHandler}
        getFirstRecordNumber={getTableRowNumber}
        dataLoading={allLeadsData.listRequesting}
      />
    </div>
  );
}
