/** @format */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ButtonSyncLoader from 'components/ButtonSyncLoader';
import AlertBox from 'components/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { loginRequestAction } from 'stateContainer/actions';
import { routeService } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function Login(props) {
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.session);
  const text = props.t('lang_admin:login');
  const [loginFailedMessageState, setLoginFailedMessageState] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (sessionData.loginError && sessionData.loginError.response) {
      let errorMessage = text.messages.apiErrorMessages.AL_GeneralError;
      const { errorLanguageCode } = sessionData.loginError.response.data;
      if (errorLanguageCode) {
        errorMessage = text.messages.apiErrorMessages[errorLanguageCode];
      }
      setLoginFailedMessageState(errorMessage);
    }
  }, [sessionData.loginError]);

  const formikValidationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email(text.messages.validation.emailInvalid)
        .required(text.messages.validation.emailRequired),
      password: Yup.string().required(text.messages.validation.passwordRequired)
    });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values) => {
      if (values.email && values.password) {
        dispatch(
          loginRequestAction({
            username: values.email,
            password: values.password,
            successUrl: routeService.admin_dashboard,
            failedUrl: routeService.admin_login
          })
        );
      } else {
        setLoginFailedMessageState(text.messages.validation.emptyCredentials);
      }
    },
    validationSchema: formikValidationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <Container className='loginpage-fullscreen'>
      <Row>
        <Col md={8} className='loginpage-field-column'>
          <div>
            <img
              src='../images/image-main-logo.svg'
              alt='Main logo'
              className='loginpage-main-logo'
            />
            <h1>{text.heading}</h1>
          </div>
          <div>
            <form
              onSubmit={(event) => {
                setLoginFailedMessageState(null);
                formik.handleSubmit(event);
              }}>
              {loginFailedMessageState && (
                <Row>
                  <Col>
                    <AlertBox
                      type='danger'
                      text={loginFailedMessageState}
                      icon={<AiOutlineExclamationCircle />}
                      iconConfig={{ size: '18px' }}
                    />
                  </Col>
                </Row>
              )}
              {text.fields.map(({ field_label, placeholder, id }, index) => (
                <Row key={index}>
                  <Col className='loginpage-field-container loginpage-field-spacing'>
                    <label htmlFor='email' className='text3m'>
                      {field_label} <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div className='form-item'>
                      <input
                        id={id}
                        name={id}
                        type={
                          id === 'password' && !showPassword
                            ? 'password'
                            : 'text'
                        }
                        placeholder={placeholder}
                        className='loginpage-field-input loginpage-description'
                        value={formik.values[id]}
                        onChange={formik.handleChange}
                      />
                      {showPassword && id === 'password' && (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          size={'lg'}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}

                      {!showPassword && id === 'password' && (
                        <FontAwesomeIcon
                          icon={faEye}
                          size={'lg'}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                    </div>
                    <label className='loginpage-validation-error-label'>
                      {formik.errors[id]}
                    </label>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col className='loginpage-field-container loginpage-field-spacing'>
                  <Button
                    type='submit'
                    className={'btn btn-pink'}
                    disabled={sessionData.requesting}>
                    {sessionData.requesting ? (
                      <ButtonSyncLoader />
                    ) : (
                      text.button.title
                    )}
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </Col>
        <Col md={4}>
          <img src='../images/image-login.png' alt='header image' />
        </Col>
      </Row>
    </Container>
  );
}
