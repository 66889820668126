/** @format */

import CommonModal from 'components/Modals/CommonModal';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveIndex, logoutRequestAction } from 'stateContainer/actions';
import { Link } from 'react-router-dom';
import { routeService } from 'services';

export default function LeftNavBar({
  text: { header, body, button_1, button_2 }
}) {
  const {
    activeNav: { index }
  } = useSelector((state) => state);
  const sessionData = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // const NewLeadsCount = ({ count }) => {
  //   if (count > 0 && count < 100) {
  //     return <div className='new-leads-count'>{count}</div>;
  //   } else if (count >= 100) {
  //     return <div className='new-leads-count'>99+</div>;
  //   } else {
  //     return <></>;
  //   }
  // };

  const NavMenuItem = ({ itemIndex, text, icon, children }) => (
    <div
      className={`dashboard-nav-buttons ${
        index === itemIndex && 'dashboard-nav-active-bar'
      }`}
      onClick={() => dispatch(updateActiveIndex(itemIndex))}>
      <img
        src={icon}
        className={`${
          index === itemIndex
            ? 'dashboard-nav-active-img'
            : 'dashboard-nav-inactive-img'
        }`}
      />
      <div className='text1r'>{text}</div>
      {children}
    </div>
  );

  return (
    <div className='dashboard-nav-container'>
      <div>
        <Link to={routeService.admin_dashboard}>
          <img
            src='../images/image-main-logo.svg'
            className='dashboard-header-image'
          />
        </Link>
        <div>
          <p className='text2m'>Menu</p>
          <div className='dashboard-nav-menu'>
            <NavMenuItem
              itemIndex={0}
              text='Dashboard'
              icon='../images/image-dashboard.svg'
            />
            <NavMenuItem
              itemIndex={1}
              text='All brokers'
              icon='../images/image-brokers.svg'
            />
            <NavMenuItem
              itemIndex={2}
              text='Leads'
              icon='../images/image-brokers.svg'
            />
            {/* <NavMenuItem
              itemIndex={3}
              text='Broker Leads'
              icon='../images/image-brokers.svg'
            /> */}
          </div>
        </div>
      </div>

      <div>
        <div className='dashboard-nav-profile-container'>
          <img src='../images/image-profile-pic.svg' alt='Main logo' />
          <div className='dashboard-profile-text'>
            <p className='text2m'>Jenz Szlapak</p>
            <p className='text3r'>jens@szlapak.se</p>
          </div>
        </div>
        <div
          className='dashboard-logout-container'
          onClick={() => handleShow()}>
          <img src='../images/image-logout.svg' />
          <p className='text1r'>Log out</p>
        </div>
      </div>
      <CommonModal
        handleClose={handleClose}
        showModal={showModal}
        header={header}
        body={body}
        button_1={button_1}
        button_2={button_2}
        handleYes={() => {
          dispatch(logoutRequestAction());
        }}
        yesResponsePending={sessionData ? sessionData.requesting : false}
      />
    </div>
  );
}
