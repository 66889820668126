/** @format */

/*****************
 **  Root-Saga  **
 *****************/
import { all, fork } from 'redux-saga/effects';
import { loginSaga } from 'stateContainer/sagas/login-saga';
import { logoutSaga } from 'stateContainer/sagas/logout-saga';
import { fetchPreOnboardedBrokersSaga } from 'stateContainer/sagas/fetch-pre-onboarded-brokers-saga';
import { approvePreOnboardedBrokerSaga } from 'stateContainer/sagas/approve-pre-onboarded-broker-saga';
import { declinePreOnboardedBrokerSaga } from 'stateContainer/sagas/decline-pre-onboarded-broker-saga';
import { fetchPostOnboardedBrokersSaga } from 'stateContainer/sagas/fetch-post-onboarded-brokers-saga';
import { declinePostOnboardedBrokerSaga } from 'stateContainer/sagas/decline-post-onboarded-broker-saga';
import { createPostOnboardedBrokerSaga } from 'stateContainer/sagas/create-post-onboarded-broker-saga';
import { fetchAllBrokersSaga } from 'stateContainer/sagas/fetch-alll-brokers-saga';
import { activateBrokerSaga } from 'stateContainer/sagas/activate-broker-saga';
import { deactivateBrokerSaga } from 'stateContainer/sagas/deactivate-broker-saga';
import { fetchAllLeadsSaga } from 'stateContainer/sagas/fetch-all-leads-saga';
import { fetchAllBrokerLeadsSaga } from 'stateContainer/sagas/fetch-all-broker-leads-saga';

const sagas = [
  loginSaga,
  logoutSaga,
  fetchPreOnboardedBrokersSaga,
  approvePreOnboardedBrokerSaga,
  declinePreOnboardedBrokerSaga,
  fetchPostOnboardedBrokersSaga,
  declinePostOnboardedBrokerSaga,
  createPostOnboardedBrokerSaga,
  fetchAllBrokersSaga,
  activateBrokerSaga,
  deactivateBrokerSaga,
  fetchAllLeadsSaga,
  fetchAllBrokerLeadsSaga
];

function* rootSaga() {
  const sagaForks = sagas.map((saga) => fork(saga));
  yield all([...sagaForks]);
}

export default rootSaga;
