/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  listRequesting: false,
  data: [],
  paging: {},
  listError: null,
  actionRequestingBrokerId: null,
  activateDeactivateRequesting: false,
  activateDeactivateError: null,
  activateDeactivateSuccess: false
};

/**
 *
 * Generalaized activate and deactivate action requested broker status update redux store save actions to
 * reduce code duplicate
 *
 * @param {*} currentState
 * @param {*} action
 * @returns
 *
 * @author Sandun Munasinghe
 * @since 1-09/2022
 */
const activateDeactivateBrokerRequestedCommonStateUpdate = (
  currentState,
  action
) => {
  return {
    ...currentState,
    activateDeactivateRequesting: false,
    actionRequestingBrokerId: action.payload.brokerId,
    activateDeactivateError: initialState.activateDeactivateError
  };
};

/**
 * Generalaized activate and deactivate action seccess broker status update redux store save actions to
 * reduce code duplicate
 *
 * @param {*} currentState
 * @param {*} action
 * @returns
 *
 * @author Sandun Munasinghe
 * @since 1-09/2022
 */
const activateDeactivateBrokerSuccessCommonStateUpdate = (
  currentState,
  action
) => {
  return {
    ...currentState,
    activateDeactivateRequesting: false,
    actionRequestingBrokerId: initialState.actionRequestingBrokerId,
    activateDeactivateError: initialState.activateDeactivateError,
    activateDeactivateSuccess: true,
    data: action.data
  };
};

/**
 * Generalaized activate and deactivate action failed broker status update redux store save actions to
 * reduce code duplicate
 *
 * @param {*} currentState
 * @param {*} action
 * @returns
 *
 * @author Sandun Munasinghe
 * @since 1-09/2022
 */
const activateDeactivateBrokerFailedCommonStateUpdate = (
  currentState,
  action
) => {
  return {
    ...currentState,
    activateDeactivateRequesting: false,
    actionRequestingBrokerId: initialState.actionRequestingBrokerId,
    activateDeactivateError: action.error,
    activateDeactivateSuccess: false
  };
};

export const allBrokersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.All_BROKERS_LIST_REQUESTED:
      return {
        ...state,
        data: initialState.data,
        paging: initialState.paging,
        listRequesting: true,
        listError: initialState.listError
      };

    case actionTypes.All_BROKERS_LIST_SUCCESS:
      return {
        ...state,
        listRequesting: false,
        data: action.data,
        paging: action.paging,
        listError: initialState.listError
      };

    case actionTypes.All_BROKERS_LIST_FAILED:
      return {
        ...state,
        listRequesting: false,
        data: initialState.data,
        listError: action.error,
        paging: initialState.paging
      };
    case actionTypes.ACTIVATE_BROKER_REQUESTED:
      return activateDeactivateBrokerRequestedCommonStateUpdate(state, action);
    case actionTypes.ACTIVATE_BROKER_SUCCESS:
      return activateDeactivateBrokerSuccessCommonStateUpdate(state, action);
    case actionTypes.ACTIVATE_BROKER_FAILED:
      return activateDeactivateBrokerFailedCommonStateUpdate(state, action);
    case actionTypes.DEACTIVATE_BROKER_REQUESTED:
      return activateDeactivateBrokerRequestedCommonStateUpdate(state, action);
    case actionTypes.DEACTIVATE_BROKER_SUCCESS:
      return activateDeactivateBrokerSuccessCommonStateUpdate(state, action);
    case actionTypes.DEACTIVATE_BROKER_FAILED:
      return activateDeactivateBrokerFailedCommonStateUpdate(state, action);

    case actionTypes.RESET_PRE_ONBOARDED_BROKERS:
      return {
        initialState
      };

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
