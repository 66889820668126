/** @format */

import React from 'react';

export default function Pagination(props) {
  if (typeof props.paging !== 'undefined') {
    const { page, recordsInCurrentPage, totalPages, totalRecords } =
      props.paging;
    const firstRecordNumber = props.getFirstRecordNumber(0);
    const lastRecordNumber = firstRecordNumber + (recordsInCurrentPage - 1);

    const Pages = () => {
      let pageElementsArray = [];

      for (let index = 1; index <= totalPages; index++) {
        if (page == index) {
          pageElementsArray.push(
            <div
              key={index}
              className='dashboard-pagination-number-active'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                props.paginationHandler(index);
              }}>
              {index}
            </div>
          );
        } else {
          pageElementsArray.push(
            <div
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                props.paginationHandler(index);
              }}>
              {index}
            </div>
          );
        }
      }
      return pageElementsArray;
    };

    return (
      <div className='dashboard-pagination-container'>
        {!props.dataLoading && !totalRecords == 0 && (
          <p className='dashboard-pagination-text'>
            Showing {firstRecordNumber}-{lastRecordNumber} of {totalRecords}{' '}
            records
          </p>
        )}
        {!props.dataLoading && totalRecords == 0 && (
          <p className='dashboard-pagination-text'>No records</p>
        )}
        <div className='dashboard-pagination-bar-container'>
          {page > 1 && (
            <img
              src='../images/image-left.svg'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                props.paginationHandler(page - 1);
              }}
            />
          )}
          <div className='dashboard-pagination-numbers-container'>
            <Pages />
          </div>
          {page < totalPages && (
            <img
              src='../images/image-right.svg'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                props.paginationHandler(page + 1);
              }}
            />
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
