/** @format */

import app from 'config/app';
import constants from 'config/constants';

const config = {
  app,
  constants
};

export default config;
