/** @format */

import React, { useState, useEffect } from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import ButtonSyncLoader from 'components/ButtonSyncLoader';
import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import appConfig from 'config/app';
import { resetPostOnboardedBrokerMessagesStore } from 'stateContainer/actions';

export default function BrokerDetail({
  text: {
    heading,
    placeholder,
    field_1,
    field_2,
    field_3,
    field_4,
    field_5,
    button_label,
    api_messages,
    validation_messages
  },
  selectedBrokerDetails,
  handleCreateBroker
}) {
  const postOnboardedBrokersData = useSelector(
    (state) => state.postOnboardedBrokersReducer
  );
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [fmEmail, setFmEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (postOnboardedBrokersData.createError) {
      setErrorMessage(api_messages.create_failed_general_error);
    } else {
      setErrorMessage(null);
    }
  }, [postOnboardedBrokersData.createError]);

  useEffect(() => {
    if (selectedBrokerDetails) {
      dispatch(resetPostOnboardedBrokerMessagesStore());
    }
  }, [selectedBrokerDetails]);

  const validatePassword = () => {
    let validated = true;
    setErrorMessage(null);
    const passwordValitorregex = new RegExp(
      appConfig.brokerNewPasswordValidatorRegex
    );
    if (!password) {
      validated = false;
      setErrorMessage(validation_messages.empty_password);
    } else if (password.length < appConfig.brokerNewPasswordMinimumLength) {
      setErrorMessage(validation_messages.short_password);
      validated = false;
    } else if (!passwordValitorregex.test(password)) {
      setErrorMessage(validation_messages.invalid_password);
      validated = false;
    }

    return validated;
  };

  const validateFmEmail = () => {
    let validated = true;
    setErrorMessage(null);
    const emailValidatorRegex = new RegExp(appConfig.emailValidatorRegex);
    if (!fmEmail) {
      validated = false;
      setErrorMessage(validation_messages.empty_fm_email);
    } else if (!emailValidatorRegex.test(fmEmail)) {
      setErrorMessage(validation_messages.invalid_fm_email);
      validated = false;
    }
    return validated;
  };

  return (
    <div className='dashboard-inner-section-2'>
      <div className='dashboard-main-heading'>
        <h2>{heading}</h2>
      </div>
      <div>
        <Row>
          <Col className='dashboard-field-container'>
            <label htmlFor='email' className='text3m'>
              {field_1.label}
            </label>
            <input
              id={field_1.id}
              name={field_1.id}
              type={'text'}
              placeholder={placeholder}
              className='dashboard-field-input'
              disabled={true}
              value={
                selectedBrokerDetails ? selectedBrokerDetails.firstName : ''
              }
            />
          </Col>
          <Col className='dashboard-field-container'>
            <label htmlFor='email' className='text3m'>
              {field_2.label}
            </label>
            <input
              id={field_2.id}
              name={field_2.id}
              type={'text'}
              placeholder={placeholder}
              className='dashboard-field-input'
              disabled={true}
              value={
                selectedBrokerDetails ? selectedBrokerDetails.lastName : ''
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className='dashboard-field-container'>
            <label htmlFor='email' className='text3m'>
              {field_3.label}
            </label>
            <input
              id={field_3.label}
              name={field_3.label}
              type={'text'}
              placeholder={placeholder}
              className='dashboard-field-input'
              disabled={true}
              value={
                selectedBrokerDetails ? selectedBrokerDetails.phoneNumber : ''
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className='dashboard-field-container'>
            <label htmlFor='email' className='text3m'>
              {field_4.label}
            </label>
            <input
              id={field_4.id}
              name={field_4.id}
              type={'email'}
              placeholder={placeholder}
              className='dashboard-field-input'
              disabled={false}
              onChange={(event) => {
                setFmEmail(event.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col className='dashboard-field-container'>
            <label htmlFor='email' className='text3m'>
              {field_5.label}
            </label>
            <input
              id={field_5.id}
              name={field_5.id}
              type={'password'}
              placeholder={placeholder}
              className='dashboard-field-input'
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            {errorMessage && (
              <Row>
                <Col>
                  <AlertBox
                    type='danger'
                    text={errorMessage}
                    icon={<AiOutlineExclamationCircle />}
                    iconConfig={{ size: '18px' }}
                  />
                </Col>
              </Row>
            )}
            {postOnboardedBrokersData.createSuccess && (
              <Row>
                <Col>
                  <AlertBox
                    type='success'
                    text={api_messages.create_success}
                    icon={<AiOutlineExclamationCircle />}
                    iconConfig={{ size: '18px' }}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col className=''>
            <Button
              className={'btn btn-pink'}
              style={{ width: '100%' }}
              onClick={() => {
                if (validatePassword() && validateFmEmail()) {
                  handleCreateBroker({
                    brokerId: selectedBrokerDetails.brokerId,
                    tempPassword: password,
                    fmEmail: fmEmail
                  });
                }
              }}
              disabled={
                !selectedBrokerDetails ||
                postOnboardedBrokersData.createRequesting
              }>
              {postOnboardedBrokersData.createRequesting ? (
                <ButtonSyncLoader />
              ) : (
                button_label
              )}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
