/** @format */

import { t } from 'i18next';

const getRoute = (key) => {
  return t(`lang_route:${key}`);
};

const routeService = {
  root: getRoute(`root`),
  admin_login: getRoute(`admin_login`),
  admin_dashboard: getRoute(`admin_dashboard`)
};

export default routeService;
