/** @format */

/** styles  */

import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles';

/** translations */
import 'assets/locale';

import React from 'react';
import Routes from 'routes';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { history } from 'stateContainer/history';
import Container from 'react-bootstrap/Container';
import ErrorBoundary from 'components/ErrorBoundary';
import { store, persistor } from 'stateContainer/store';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';

import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider>
        <Provider store={store}>
          <ConnectedRouter history={history} noInitialPop>
            <Container fluid>
              <Routes />
            </Container>
          </ConnectedRouter>
        </Provider>
      </HelmetProvider>
    </PersistGate>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
