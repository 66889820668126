/** @format */

import { push } from 'connected-react-router';
import { adminloginApiService } from 'services/apiService';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
import moment from 'moment';

function* workerSaga({ payload }) {
  try {
    const response = yield call(adminloginApiService, payload);
    if (response.data.status == 'success') {
      const accessToken = response.data.data.AuthenticationResult.AccessToken;
      const refreshToken = response.data.data.AuthenticationResult.RefreshToken;
      const expiresInSeconds =
        response.data.data.AuthenticationResult.ExpiresIn;

      /**
       * finally conclude the login action
       */

      yield put({
        type: actionTypes.LOGIN_SUCCESS,
        data: {
          accessToken,
          refreshToken
        },
        sessionEndTime: moment().add(expiresInSeconds, 'seconds').toDate()
      });
    } else {
      throw new Error('Login failed');
    }

    yield put(push(payload.successUrl));
  } catch (error) {
    yield put({ type: actionTypes.LOGIN_FAILED, error });
    yield put(push(payload.failedUrl));
  }
}

export function* loginSaga() {
  yield takeLatest(actionTypes.LOGIN_REQUESTED, workerSaga);
}
