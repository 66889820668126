/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  index: 0
};

export const activeNavReducer = (state = initialState, action) => {
  if (action.type === actionTypes.ACTIVE_INDEX) {
    return { ...state, index: action.payload };
  } else {
    return state;
  }
};
