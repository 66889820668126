/** @format */

import { adminLogoutApiService } from 'services/apiService';
import { push } from 'connected-react-router';
import { persistor, store } from 'stateContainer/store';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

const LOGOUT_LANDING_URL = '/login';

function* workerSaga() {
  try {
    const { session } = store.getState();
    const refreshToken = session.data.refreshToken;

    const response = yield call(adminLogoutApiService, { refreshToken });
    localStorage.removeItem('persist:root');
    persistor.purge();

    if (response.data.status == 'success') {
      yield put({ type: actionTypes.LOGOUT_SUCCESS });
      yield put(push(LOGOUT_LANDING_URL));
    } else {
      throw new Error('Logout failed');
    }
  } catch (error) {
    yield put({ type: actionTypes.LOGOUT_FAILED, error });
    yield put(push(LOGOUT_LANDING_URL));
  }
}

export function* logoutSaga() {
  yield takeLatest(actionTypes.LOGOUT_REQUESTED, workerSaga);
}
