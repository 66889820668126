/** @format */
/** A custom Hook that redirects users to private routes and redirects them to a given url when not authenticated */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { routeService } from 'services';

export default function PrivateRoute({ children, ...rest }) {
  const { data = {}, isAuthenticated } = useSelector((state) => state.session);

  const isLoggedIn = data && data.accessToken && isAuthenticated;

  return (
    <>
      <Route {...rest}>
        {isLoggedIn ? (
          <>{children}</>
        ) : (
          <Redirect to={routeService.admin_login} />
        )}
      </Route>
    </>
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};
