/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  listRequesting: false,
  approvalRequesting: false,
  declineRequesting: false,
  data: [],
  paging: {},
  approvalSuccess: false,
  declineSuccess: false,
  listError: null,
  approvalError: null,
  declineError: null
};

export const preOnboardedBrokersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRE_ONBOARDED_BROKERS_LIST_REQUESTED:
      return {
        ...state,
        data: initialState.brokersList,
        paging: initialState.paging,
        listRequesting: true,
        listError: initialState.listError
      };

    case actionTypes.PRE_ONBOARDED_BROKERS_LIST_SUCCESS:
      return {
        ...state,
        listRequesting: false,
        data: action.data,
        paging: action.paging,
        listError: initialState.listError
      };

    case actionTypes.PRE_ONBOARDED_BROKERS_LIST_FAILED:
      return {
        ...state,
        listRequesting: false,
        data: initialState.brokersList,
        listError: action.error,
        paging: initialState.paging
      };
    /**Broker approval */
    case actionTypes.APPROVE_PRE_ONBOARDED_BROKERS_REQUESTED:
      return {
        ...state,
        approvalRequesting: true,
        approvalError: initialState.approvalError,
        approvalSuccess: initialState.approvalSuccess
      };
    case actionTypes.APPROVE_PRE_ONBOARDED_BROKERS_SUCCESS:
      return {
        ...state,
        approvalRequesting: false,
        approvalError: initialState.approvalError,
        approvalSuccess: true
      };
    case actionTypes.APPROVE_PRE_ONBOARDED_BROKERS_FAILED:
      return {
        ...state,
        approvalRequesting: false,
        approvalError: action.error,
        approvalSuccess: false
      };
    /**Broker decline */
    case actionTypes.DECLINE_PRE_ONBOARDED_BROKERS_REQUESTED:
      return {
        ...state,
        declineRequesting: true,
        declineError: initialState.declineError,
        declineSuccess: initialState.declineSuccess
      };
    case actionTypes.DECLINE_PRE_ONBOARDED_BROKERS_SUCCESS:
      return {
        ...state,
        declineRequesting: false,
        declineError: initialState.declineError,
        declineSuccess: true
      };
    case actionTypes.DECLINE_PRE_ONBOARDED_BROKERS_FAILED:
      return {
        ...state,
        declineRequesting: false,
        declineError: action.error,
        declineSuccess: false
      };
    case actionTypes.RESET_PRE_ONBOARDED_BROKERS:
      return {
        ...initialState
      };

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
