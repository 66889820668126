/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

export default function RegularHeader(props) {
  const { buttonText, homepageUrl, getButtonColor } = props;

  return (
    <>
      <div className='header'>
        <div className='header-left-container'>
          <Link to={homepageUrl} className='logo'>
            <div className='logo-fria-maklare-dark' />
          </Link>
        </div>
        <div className='header-middle-container'></div>
        <div className='header-right-container'>
          {buttonText.map((item, key) => (
            <div className={`header-button-item`} key={key}>
              <Link to={item.props.url}>
                <Button className={`btn btn-${getButtonColor(key)}`}>
                  {item.text}
                </Button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
