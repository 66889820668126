/** @format */

/********************
 **  Root-Reducer  **
 ********************/

import { combineReducers } from 'redux';
import { history } from 'stateContainer/history';
import { connectRouter } from 'connected-react-router';
import * as actionTypes from 'stateContainer/action-types';

import { loginReducer } from 'stateContainer/reducers/login-reducer';
import { activeNavReducer } from './activeindex-reducer';
import { preOnboardedBrokersReducer } from 'stateContainer/reducers/pre-onboarded-brokers-reducer';
import { postOnboardedBrokersReducer } from 'stateContainer/reducers/post-onboarded-brokers-reducer';
import { allBrokersReducer } from 'stateContainer/reducers/all-brokers-reducer';
import { leadsReducer } from 'stateContainer/reducers/leads-reducer';
import { brokerLeadsReducer } from 'stateContainer/reducers/broker-leads-reducer';

const appReducer = combineReducers({
  router: connectRouter(history),
  session: loginReducer,
  activeNav: activeNavReducer,
  preOnboardedBrokersReducer,
  postOnboardedBrokersReducer,
  allBrokers: allBrokersReducer,
  leads: leadsReducer,
  brokerLeads: brokerLeadsReducer
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    return {
      router: state.router,
      activeNav: {
        index: 0
      },
      session: {
        requesting: false
      }
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
