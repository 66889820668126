/** @format */
import config from 'config';
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { logoutRequestAction } from 'stateContainer/actions';
import { useDispatch, useSelector } from 'react-redux';
import LeftNavBar from 'components/Dashboard/LeftNavBar';
import AllBrokers from 'components/Dashboard/AllBrokers';
import Leads from 'components/Dashboard/Leads';
import BrokerLeads from 'components/Dashboard/BrokerLeads';
import PreOnboarding from 'components/Dashboard/PreOnboarding';
import PostOnboarding from 'components/Dashboard/PostOnboarding';
import { encode } from 'js-base64';

export default function Dashboard(props) {
  const dispatch = useDispatch();
  const {
    preonboarding,
    postonboarding,
    allbrokers,
    logoutModal,
    brokerDetail,
    leads,
    broker_leads
  } = props.t('lang_admin:dashboard');

  const {
    activeNav: { index },
    session: { sessionEndTime }
  } = useSelector((state) => state);

  const { accessToken = undefined } = useSelector(
    (state) => state.session.data
  );

  const encodedAccessToken = encode(`Bearer ${accessToken}`);

  useEffect(() => {
    const sessionHasExpired = new Date(sessionEndTime) < new Date();
    if (sessionHasExpired) {
      dispatch(logoutRequestAction());
    }
  }, []);

  const copyToClipBoard = async (copiedText) => {
    await navigator.clipboard.writeText(copiedText);
  };

  const handleCopyLinkToClipBoard = ({
    brokerId,
    destination,
    brokerSlug,
    searchable
  }) => {
    let destinationUrl = `${config.app.onBoardingOriginUrl}?brokerId=${brokerId}`;
    if (destination === 'profile') {
      destinationUrl = `${config.app.brokerPanelUrl}?profileId=${brokerId}&profileRef=${encodedAccessToken}`;
      if (brokerSlug && searchable) {
        destinationUrl = `${config.app.baseUrl}/${brokerSlug}`;
      }
    }

    copyToClipBoard(destinationUrl);
  };
  return (
    <Container fluid className='dashboard-main-container'>
      <LeftNavBar text={logoutModal} />

      <div className='dashboard-body-container'>
        {index === 0 && (
          <>
            <PreOnboarding
              text={preonboarding}
              handleCopyLinkToClipBoard={handleCopyLinkToClipBoard}
            />
            <PostOnboarding
              text={postonboarding}
              brokerDetailText={brokerDetail}
            />
          </>
        )}
        {index === 1 && (
          <AllBrokers
            text={allbrokers}
            handleCopyLinkToClipBoard={handleCopyLinkToClipBoard}
          />
        )}
        {index === 2 && (
          <Leads
            text={leads}
            handleCopyLinkToClipBoard={handleCopyLinkToClipBoard}
          />
        )}
        {index === 3 && (
          <BrokerLeads
            text={broker_leads}
            handleCopyLinkToClipBoard={handleCopyLinkToClipBoard}
          />
        )}
      </div>
    </Container>
  );
}
