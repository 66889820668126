/** @format */

import { push } from 'connected-react-router';
import { deactivateBrokerApiService } from 'services/apiService';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
import { store } from 'stateContainer/store';

function* workerSaga({ payload }) {
  try {
    const response = yield call(deactivateBrokerApiService, payload);
    if (response.data.status == 'success') {
      /**
       * finally conclude the status action
       */

      const { allBrokers } = store.getState();

      const updatedBrokerData = allBrokers.data.map((brokerRecord) => {
        if (brokerRecord.brokerId == payload.brokerId) {
          return {
            ...brokerRecord,
            brokerStatus: 'inactive'
          };
        } else {
          return brokerRecord;
        }
      });

      yield put({
        type: actionTypes.DEACTIVATE_BROKER_SUCCESS,
        data: updatedBrokerData
      });
    } else {
      throw new Error('Data fetch failed');
    }
  } catch (error) {
    if (error.response.data.error == 'NotAuthorized') {
      yield put(push({ type: actionTypes.LOGOUT_REQUESTED }));
      yield put(push('/login'));
    }
    yield put({ type: actionTypes.DEACTIVATE_BROKER_FAILED, error });
  }
}

export function* deactivateBrokerSaga() {
  yield takeLatest(actionTypes.DEACTIVATE_BROKER_REQUESTED, workerSaga);
}
