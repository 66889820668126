/** @format */

import { push } from 'connected-react-router';
import { approvePreOnboardedBrokerApiService } from 'services/apiService';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const response = yield call(approvePreOnboardedBrokerApiService, payload);
    if (response.data.status == 'success') {
      /**
       * finally conclude the login action
       */

      yield put({
        type: actionTypes.APPROVE_PRE_ONBOARDED_BROKERS_SUCCESS,
        data: response.data.data
      });
    } else {
      throw new Error('Broker approve failed');
    }
  } catch (error) {
    if (error.response.data.error == 'NotAuthorized') {
      yield put(push({ type: actionTypes.LOGOUT_REQUESTED }));
      yield put(push('/login'));
    }
    yield put({
      type: actionTypes.APPROVE_PRE_ONBOARDED_BROKERS_FAILED,
      error
    });
  }
}

export function* approvePreOnboardedBrokerSaga() {
  yield takeLatest(
    actionTypes.APPROVE_PRE_ONBOARDED_BROKERS_REQUESTED,
    workerSaga
  );
}
