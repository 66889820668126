/** @format */

import React, {
  useEffect
  // useState
} from 'react';
import {
  Table,
  // Button,
  InputGroup
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faXmarkCircle,
  // faCircleCheck,
  faSearch
  // faCopy
} from '@fortawesome/free-solid-svg-icons';
import Pagination from '../Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { listAllLeadsRequestedAction } from 'stateContainer/actions';
import moment from 'moment';
import TableDataLoader from 'components/TableDataLoader';
// import ButtonSyncLoader from 'components/ButtonSyncLoader';
import appConfig from 'config/app';
import StatusIndicator from 'components/StatusIndicator';

const recordsPerPage = 8;

const LEAD_STATUSES = {
  NEW: 'NEW',
  RESPONDED: 'RESPONDED'
};

const LEAD_SOURCE_KEYS = {
  FM_HOME: 'fm_home',
  FM_SELL_AND_BUY: 'fm_sell_and_buy',
  FM_BROKER_PROFILE: 'fm_broker_profile',
  FM_OBJECT_PROFILE: 'fm_object_profile',
  FV_HOME: 'fv_home'
};

const LEAD_SOURCE_LABELS = {
  [LEAD_SOURCE_KEYS.FM_HOME]: 'Fria Maklare Home Page',
  [LEAD_SOURCE_KEYS.FM_SELL_AND_BUY]: 'Fria Maklare Sell and Buy Page',
  [LEAD_SOURCE_KEYS.FM_BROKER_PROFILE]: 'Fria Maklare Broker Profile',
  [LEAD_SOURCE_KEYS.FM_OBJECT_PROFILE]: 'Fria Maklare Object Profile',
  [LEAD_SOURCE_KEYS.FV_HOME]: 'Fri Vadering Home Page'
};

export default function Leads({
  text: {
    heading,
    table: { table_headings }
  }
  // handleCopyLinkToClipBoard
}) {
  // const [searchName, setSearchName] = useState('');

  useEffect(() => {
    dispatch(
      listAllLeadsRequestedAction({
        page: 1,
        recordsPerPage
      })
    );
  }, []);

  const allLeadsData = useSelector((state) => state.leads);

  const dispatch = useDispatch();

  const getTableRowNumber = (dataIndex) => {
    const recordsPerpage = allLeadsData.paging.recordsPerPage;
    const currentpage = allLeadsData.paging.page;

    return (currentpage - 1) * recordsPerpage + (dataIndex + 1);
  };

  const paginationHandler = (pageNumber) => {
    let params = {
      page: pageNumber,
      recordsPerPage
    };
    // if (searchName != '') {
    //   params.name = searchName;
    // }
    dispatch(listAllLeadsRequestedAction(params));
  };

  // const activateDeactivateBrokerHandler = (brokerId, action = 'activate') => {
  //   if (action == 'activate') {
  //     dispatch(activateBrokerRequestedAction({ brokerId }));
  //   } else {
  //     dispatch(deactivateBrokerRequestedAction({ brokerId }));
  //   }
  // };

  const searchBrokersHandler = (event) => {
    event.preventDefault();
    let params = {
      page: 1,
      recordsPerPage
    };
    // if (searchName != '') {
    //   params.name = searchName;
    // }
    dispatch(listAllLeadsRequestedAction(params));
  };

  const LeadOriginValue = ({ sourceKey }) => {
    return LEAD_SOURCE_LABELS[sourceKey] ?? '-';
  };

  const LeadsDataRow = ({ index, dataRow }) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{dataRow.leadType}</td>
        <td>{dataRow.name}</td>
        <td>{dataRow.email}</td>
        <td> {dataRow.location}</td>
        <td
          style={{
            textAlign: 'center'
          }}>
          <StatusIndicator
            greenStatus={dataRow.status === LEAD_STATUSES.NEW}
            greyStatus={dataRow.status === LEAD_STATUSES.RESPONDED}
            text={dataRow.status}
          />
        </td>
        <td
          style={{
            textAlign: 'center'
          }}>
          <LeadOriginValue sourceKey={dataRow.source} />
        </td>
        <td>
          {dataRow.createdDatetime
            ? moment(dataRow.createdDatetime).format(
                appConfig.defaultMomentDateFormat
              )
            : '-'}
        </td>

        <td>
          {/* <Button className={'btn btn-pink'}>
            <FontAwesomeIcon icon={faCopy} size='lg' color='white' />
            Mark as attended
          </Button> */}
        </td>
      </tr>
    );
  };

  return (
    <div className='leads-container'>
      <div>
        <div className='leads-heading'>
          <h2>{heading}</h2>
          <Form className='leads-search-field' onSubmit={searchBrokersHandler}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon
                  icon={faSearch}
                  color='var(--color-neutral-dark-600)'
                  style={{ cursor: 'pointer' }}
                  // onClick={searchBrokersHandler}
                />
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Search'
                // onChange={(event) => {
                //   setSearchName(event.target.value);
                // }}
              />
            </InputGroup>
          </Form>
        </div>
        {allLeadsData.listRequesting && <TableDataLoader />}
        {!allLeadsData.listRequesting && (
          <Table bordered className='leads-table' hover>
            <thead>
              <tr>
                {table_headings.map((table_heading, index) => (
                  <th key={index}>{table_heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {allLeadsData.data.map((row, index) => (
                <LeadsDataRow key={index} index={index} dataRow={row} />
              ))}
            </tbody>
          </Table>
        )}
      </div>

      <Pagination
        paging={allLeadsData.paging}
        paginationHandler={paginationHandler}
        getFirstRecordNumber={getTableRowNumber}
        dataLoading={allLeadsData.listRequesting}
      />
    </div>
  );
}
