/** @format */

import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

/**
 * Custom loader animation element for button
 *
 * @param {*} props
 * @returns JSX
 *
 * @author Sandun Munasinghe
 * @since 22-08-2022
 */
export default function TableDataLoader(props) {
  const className = props.className ? props.className : '';
  return (
    <div className={`table-data-loader ${className}`}>
      <BeatLoader
        color={props.color ? props.color : 'var(--color-primary-pink)'}
        loading={true}
        size={30}
        speedMultiplier={1}
      />
    </div>
  );
}
