/** @format */

import * as actionTypes from 'stateContainer/action-types';

export const loginRequestAction = (payload) => ({
  type: actionTypes.LOGIN_REQUESTED,
  payload
});

export const logoutRequestAction = () => ({
  type: actionTypes.LOGOUT_REQUESTED
});

export const updateActiveIndex = (payload) => ({
  type: actionTypes.ACTIVE_INDEX,
  payload
});

/** get pre onboarded broker actions */
export const getPreOnboardedBrokerListRequestedAction = (payload) => ({
  type: actionTypes.PRE_ONBOARDED_BROKERS_LIST_REQUESTED,
  payload
});

/** decline pre onboarded broker actions */
export const declinePreOnboardedBrokerRequestedAction = (payload) => ({
  type: actionTypes.DECLINE_PRE_ONBOARDED_BROKERS_REQUESTED,
  payload
});

/** approve pre onboarded broker actions */
export const approvePreOnboardedBrokerRequestedAction = (payload) => ({
  type: actionTypes.APPROVE_PRE_ONBOARDED_BROKERS_REQUESTED,
  payload
});

/** get post onboarded broker actions */
export const getPostOnboardedBrokerListRequestedAction = (payload) => ({
  type: actionTypes.POST_ONBOARDED_BROKERS_LIST_REQUESTED,
  payload
});

/** decline post onboarded broker actions */
export const declinePostOnboardedBrokerRequestedAction = (payload) => ({
  type: actionTypes.DECLINE_POST_ONBOARDED_BROKER_REQUESTED,
  payload
});

/** create post onboarded broker actions */
export const createPostOnboardedBrokerRequestedAction = (payload) => ({
  type: actionTypes.CREATE_POST_ONBOARDED_BROKER_REQUESTED,
  payload
});

/** reset stores actions */
export const resetPostOnboardedBrokersStore = () => ({
  type: actionTypes.RESET_POST_ONBOARDED_BROKERS
});

export const resetPostOnboardedBrokerMessagesStore = () => ({
  type: actionTypes.RESET_MESSAGE_STATE
});

/** list all brokers actions */
export const listAllBrokersRequestedAction = (payload) => ({
  type: actionTypes.All_BROKERS_LIST_REQUESTED,
  payload
});

/** activate broker actions */
export const activateBrokerRequestedAction = (payload) => ({
  type: actionTypes.ACTIVATE_BROKER_REQUESTED,
  payload
});

/** deactivate broker actions */
export const deactivateBrokerRequestedAction = (payload) => ({
  type: actionTypes.DEACTIVATE_BROKER_REQUESTED,
  payload
});

/** list all leads actions */
export const listAllLeadsRequestedAction = (payload) => ({
  type: actionTypes.All_LEADS_LIST_REQUESTED,
  payload
});

/** list all broker leads actions */
export const listAllBrokerLeadsRequestedAction = (payload) => ({
  type: actionTypes.All_BROKER_LEADS_LIST_REQUESTED,
  payload
});
