/** @format */

import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  faCircleCheck,
  faXmarkCircle,
  faCopy
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonModal from 'components/Modals/CommonModal';
import AcceptNewBrokerModal from 'components/Modals/AcceptNewBrokerModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPreOnboardedBrokerListRequestedAction,
  approvePreOnboardedBrokerRequestedAction,
  declinePreOnboardedBrokerRequestedAction
} from 'stateContainer/actions';
import Pagination from '../Pagination';
import moment from 'moment';
import TableDataLoader from 'components/TableDataLoader';
import appConfig from 'config/app';

export default function PreOnboarding({
  text: {
    heading,

    declineModal: {
      header: declineHeader,
      body: declineBody,
      body_next_line: declineBodyNextLine,
      button_1: declineButton1,
      button_2: declineButton2
    },
    acceptModal: {
      header: acceptHeader,
      body: acceptBody,
      button_1: acceptButton1,
      button_2: acceptButton2
    },
    table: { headings, button_decline, button_approve, button_copy },
    tabs
  },
  handleCopyLinkToClipBoard
}) {
  const dispatch = useDispatch();
  const preOnboardedBrokersData = useSelector(
    (state) => state.preOnboardedBrokersReducer
  );

  const [showModalAccept, setShowModalAccept] = useState(false);
  const handleCloseAccept = () => setShowModalAccept(false);
  const handleShowAccept = (brokerId) => setShowModalAccept({ brokerId });

  const [showModalDecline, setShowModalDecline] = useState(false);
  const handleCloseDecline = () => setShowModalDecline(false);
  const handleShowDecline = (brokerId) => setShowModalDecline({ brokerId });

  const [tabKey, setTabKey] = useState('pending');

  const { logoutInitiated } = useSelector((state) => state.session);

  useEffect(() => {
    handleCloseDecline();
    handleCloseAccept();
    if (!preOnboardedBrokersData.listRequesting && !logoutInitiated) {
      dispatch(
        getPreOnboardedBrokerListRequestedAction({
          page: 1,
          recordsPerPage: 5,
          adminAproved: tabKey
        })
      );
    }
  }, [
    preOnboardedBrokersData.approvalSuccess,
    preOnboardedBrokersData.declineSuccess,
    tabKey
  ]);

  const handleDeclineBroker = () => {
    const brokerId = showModalDecline.brokerId;
    dispatch(
      declinePreOnboardedBrokerRequestedAction({
        brokerId
      })
    );
  };
  const handleApproveBroker = () => {
    const brokerId = showModalAccept.brokerId;

    dispatch(
      approvePreOnboardedBrokerRequestedAction({
        brokerId
      })
    );
    handleCopyLinkToClipBoard({ brokerId });
  };

  const getTableRowNumber = (dataIndex) => {
    const recordsPerpage = preOnboardedBrokersData.paging.recordsPerPage;
    const currentpage = preOnboardedBrokersData.paging.page;

    return (currentpage - 1) * recordsPerpage + (dataIndex + 1);
  };

  const paginationHandler = (pageNumber) => {
    dispatch(
      getPreOnboardedBrokerListRequestedAction({
        page: pageNumber,
        recordsPerPage: 5
      })
    );
  };

  const TableHeadings = ({ headings }) => {
    return (
      <thead>
        <tr>
          {headings.map((heading, index) => (
            <th key={index}>{heading}</th>
          ))}
        </tr>
      </thead>
    );
  };

  const TableRows = ({ row, index, approvedOnly }) => {
    return (
      <tr key={index}>
        <td>{getTableRowNumber(index)}</td>
        <td>{row.firstName + ' ' + row.lastName}</td>
        <td>
          {moment(row.updatedDatetime).format(
            appConfig.defaultMomentDateFormat
          )}
        </td>
        <td>{row.email}</td>
        <td>{row.fmEmail}</td>
        <td>
          <div className='dashboard-table-buttons'>
            {approvedOnly ? (
              <div>
                <Button
                  onClick={() =>
                    handleCopyLinkToClipBoard({
                      brokerId: row.brokerId,
                      destination: 'onboarding'
                    })
                  }
                  className={'btn btn-pink dashboard-table-button-spacing '}>
                  <FontAwesomeIcon
                    icon={faCopy}
                    size='lg'
                    color='var(--color-neutral-neutral)'
                  />
                  {button_copy}
                </Button>
              </div>
            ) : (
              <>
                <Button
                  onClick={() => handleShowDecline(row.brokerId)}
                  className={'btn btn-white dashboard-table-button-spacing '}>
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    size='lg'
                    color='var(--color-neutral-dark-600)'
                  />
                  {button_decline}
                </Button>
                <Button
                  className={'btn btn-pink'}
                  onClick={() => handleShowAccept(row.brokerId)}>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size='lg'
                    color='var(--color-neutral-neutral)'
                  />
                  {button_approve}
                </Button>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className='dashboard-body-section-1'>
      <div className='dashboard-main-heading'>
        <h2>{heading}</h2>
      </div>
      <div>
        {preOnboardedBrokersData.listRequesting && <TableDataLoader />}
        {!preOnboardedBrokersData.listRequesting && (
          <>
            <Tabs
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
              className='mb-5'>
              <Tab eventKey='pending' title={tabs.preonboarding}>
                <Table bordered className='dashboard-preonboarding-table' hover>
                  <TableHeadings headings={headings} />
                  <tbody>
                    {preOnboardedBrokersData.data &&
                      preOnboardedBrokersData.data.map((row, index) => (
                        <TableRows row={row} index={index} key={index} />
                      ))}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey='approved' title={tabs.approved}>
                <Table bordered className='dashboard-preonboarding-table'>
                  <TableHeadings headings={headings} />
                  <tbody>
                    {preOnboardedBrokersData.data &&
                      preOnboardedBrokersData.data.map((row, index) => (
                        <TableRows
                          key={index}
                          row={row}
                          index={index}
                          approvedOnly={true}
                        />
                      ))}
                  </tbody>
                </Table>
              </Tab>
            </Tabs>
          </>
        )}
        <Pagination
          paging={preOnboardedBrokersData.paging}
          paginationHandler={paginationHandler}
          getFirstRecordNumber={getTableRowNumber}
          dataLoading={preOnboardedBrokersData.listRequesting}
        />
      </div>
      {/* Accept Modal */}
      <AcceptNewBrokerModal
        handleClose={handleCloseAccept}
        showModal={showModalAccept}
        header={acceptHeader}
        body={acceptBody}
        button_1={acceptButton1}
        button_2={acceptButton2}
        handleYes={handleApproveBroker}
        yesResponsePending={preOnboardedBrokersData.approvalRequesting}
      />
      {/* Decline Modal */}
      <CommonModal
        handleClose={handleCloseDecline}
        showModal={showModalDecline}
        header={declineHeader}
        body={declineBody}
        body_next_line={declineBodyNextLine}
        button_1={declineButton1}
        button_2={declineButton2}
        handleYes={handleDeclineBroker}
        yesResponsePending={preOnboardedBrokersData.declineRequesting}
      />
    </div>
  );
}
