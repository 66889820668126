/** @format */

export default {
  /**
   * application flows
   */
  flows: {
    admin: 'admin',
    registration: 'registration',
    common: 'common'
  },
  /**
   * flowOrigins to identify the source of the flow
   */
  flowOrigins: {
    admin: 'admin',
    registration: 'registration',
    common: 'common'
  },
  /**
   * views represent every single page in the application excluding modals
   */
  views: {
    page_not_found: 'page_not_found',
    login: 'login',
    dashboard: 'dashboard'
  },
  colors: {
    /**
     * this object would represent the classNames of colors instead of the actual color
     **/

    white: 'btn-white',
    pink: 'btn-pink'
  }
};
