/** @format */
import lang_route from './lang_route.json';
import lang_error from './lang_error-sample.json';
import lang_common from './lang_common.json';

import lang_footer from './lang_footer.json';
import lang_header from './lang_header.json';
import lang_registration from './lang_registration.json';
import lang_onboarding from './lang_onboarding.json';
import lang_admin from './lang_admin.json';

const swedish = {
  lang_error,
  lang_route,
  lang_common,
  lang_footer,
  lang_header,
  lang_registration,
  lang_onboarding,
  lang_admin
};

export default swedish;
