/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  sessionEndTime: 0,
  isAuthenticated: false,
  sessionIsAboutToExpire: false,
  showSessionExpiredMessage: false,
  requesting: false,
  data: {
    accessToken: '',
    refreshToken: ''
  },
  loginError: null,
  logoutError: null,
  logoutInitiated: false
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        sessionEndTime: action.sessionEndTime,
        requesting: false,
        data: action.data
      };

    case actionTypes.LOGIN_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        requesting: false,
        data: initialState.data,
        loginError: action.error
      };

    case actionTypes.LOGOUT_REQUESTED:
      return {
        ...state,
        logoutInitiated: true,
        requesting: true
      };

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        data: initialState.data,
        requesting: false,
        isAuthenticated: false,
        sessionEndTime: initialState.sessionEndTime
      };

    case actionTypes.LOGOUT_FAILED:
      return { ...initialState, logoutError: action.error };

    default:
      return state;
  }
};
