/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  listRequesting: false,
  data: [],
  paging: {},
  listError: null
};

export const brokerLeadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.All_BROKER_LEADS_LIST_REQUESTED:
      return {
        ...state,
        data: initialState.data,
        paging: initialState.paging,
        listRequesting: true,
        listError: initialState.listError
      };

    case actionTypes.All_BROKER_LEADS_LIST_SUCCESS:
      return {
        ...state,
        listRequesting: false,
        data: action.data,
        paging: action.paging || initialState.paging,
        listError: initialState.listError
      };

    case actionTypes.All_BROKER_LEADS_LIST_FAILED:
      return {
        ...state,
        listRequesting: false,
        data: initialState.data,
        listError: action.error,
        paging: initialState.paging
      };

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
