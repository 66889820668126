/** @format */

import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

/**
 * Custom loader animation element for button
 *
 * @param {*} props
 * @returns JSX
 *
 * @author Sandun Munasinghe
 * @since 22-08-2022
 */
export default function ButtonSyncLoader(props) {
  const className = props.className ? props.className : '';
  return (
    <div className={`button-loader ${className} `}>
      <SyncLoader
        color={props.color ? props.color : 'var(--color-primary-pink)'}
        loading={true}
        className='button-loader'
        size={props.size ? props.size : 6}
      />
    </div>
  );
}
