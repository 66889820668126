/** @format */

import React from 'react';
import MobileHeader from 'components/Layout/Header/MobileHeader';
import RegularHeader from 'components/Layout/Header/RegularHeader';

export default function Header(props) {
  const { t, routeService, isMobileView } = props;

  const [show, setShow] = React.useState(false);

  const getButtonColor = (key) => {
    return key === 0 ? 'white' : 'pink';
  };

  const homepageUrl = routeService.root;

  const buttonText = t('lang_header:buttons');

  return (
    <>
      <RegularHeader
        t={t}
        homepageUrl={homepageUrl}
        buttonText={buttonText}
        getButtonColor={getButtonColor}
      />

      {isMobileView && (
        <MobileHeader
          t={t}
          buttonText={buttonText}
          show={show}
          setShow={setShow}
          getButtonColor={getButtonColor}
        />
      )}
    </>
  );
}
