/** @format */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

export const StatusIndicatorFlag = {
  GREEN: 'GREEN',
  RED: 'RED',
  GREY: 'GREY',
  NONE: 'NONE'
};

export default function StatusIndicator({
  greenStatus = false,
  redStatus = false,
  greyStatus = false,
  text = '',
  icon = faCircleCheck
}) {
  const GreenIndicator = () => (
    <div className='allbrokers-table-status-container allbrokers-table-status-container-green'>
      <FontAwesomeIcon icon={icon} size='sm' color='white' />
      <p className='text4r'>{text}</p>
    </div>
  );

  const RedIndicator = () => (
    <div className='allbrokers-table-status-container allbrokers-table-status-container-red'>
      <FontAwesomeIcon icon={icon} size='sm' color='white' />
      <p className='text4r'>{text}</p>
    </div>
  );

  const GreyIndicator = () => (
    <div className='allbrokers-table-status-container allbrokers-table-status-container-grey'>
      <FontAwesomeIcon icon={icon} size='sm' color='white' />
      <p className='text4r'>{text}</p>
    </div>
  );

  const NoneIndicator = () => <p>-</p>;

  if (greenStatus) {
    return <GreenIndicator />;
  } else if (redStatus) {
    return <RedIndicator />;
  } else if (greyStatus) {
    return <GreyIndicator />;
  } else {
    return <NoneIndicator />;
  }
}
