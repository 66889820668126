/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  listRequesting: false,
  createRequesting: false,
  declineRequesting: false,
  data: [],
  paging: {},
  createSuccess: false,
  declineSuccess: false,
  listError: null,
  createError: null,
  declineError: null
};

export const postOnboardedBrokersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POST_ONBOARDED_BROKERS_LIST_REQUESTED:
      return {
        ...state,
        data: initialState.data,
        paging: initialState.paging,
        listRequesting: true,
        listError: initialState.listError
      };

    case actionTypes.POST_ONBOARDED_BROKERS_LIST_SUCCESS:
      return {
        ...state,
        listRequesting: false,
        data: action.data,
        paging: action.paging,
        listError: initialState.listError
      };

    case actionTypes.POST_ONBOARDED_BROKERS_LIST_FAILED:
      return {
        ...state,
        listRequesting: false,
        data: initialState.data,
        listError: action.error,
        paging: initialState.paging
      };
    /**Broker create */
    case actionTypes.CREATE_POST_ONBOARDED_BROKER_REQUESTED:
      return {
        ...state,
        createRequesting: true,
        createError: initialState.createError,
        createSuccess: initialState.createSuccess
      };
    case actionTypes.CREATE_POST_ONBOARDED_BROKER_SUCCESS:
      return {
        ...state,
        createRequesting: false,
        createError: initialState.createError,
        createSuccess: true
      };
    case actionTypes.CREATE_POST_ONBOARDED_BROKER_FAILED:
      return {
        ...state,
        createRequesting: false,
        createError: action.error,
        createSuccess: false
      };
    /**Broker decline */
    case actionTypes.DECLINE_POST_ONBOARDED_BROKER_REQUESTED:
      return {
        ...state,
        declineRequesting: true,
        declineError: initialState.declineError,
        declineSuccess: initialState.declineSuccess
      };
    case actionTypes.DECLINE_POST_ONBOARDED_BROKER_SUCCESS:
      return {
        ...state,
        declineRequesting: false,
        declineError: initialState.declineError,
        declineSuccess: true
      };
    case actionTypes.DECLINE_POST_ONBOARDED_BROKER_FAILED:
      return {
        ...state,
        declineRequesting: false,
        declineError: action.error,
        declineSuccess: false
      };
    case actionTypes.RESET_POST_ONBOARDED_BROKERS:
      return {
        ...initialState
      };
    case actionTypes.RESET_MESSAGE_STATE:
      return {
        ...state,
        createSuccess: false,
        declineSuccess: false,
        listError: null,
        createError: null,
        declineError: null
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
