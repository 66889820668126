/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import NavigationLinks from 'components/Layout/Footer/NavigationLinks';
import SocialIcons from 'components/Layout/Footer/SocialIcons';

export default function Footer(props) {
  const { t, routeService } = props;
  const year = new Date().getFullYear();

  const copyright = t(`lang_footer:copyright.text`, { year: year });
  const homepageUrl = routeService.root;
  const socialIconsList = t(`lang_footer:socialIcons`);
  return (
    <footer className='footer'>
      <Container>
        <div className='footer-top'>
          <div className='footer-top-left'>
            <Link to={homepageUrl} className='logo'>
              <div className='logo-fria-maklare-light' />
            </Link>
          </div>
          <div className='footer-top-middle'>
            <div className='footer-links-container'>
              <NavigationLinks {...props} />
            </div>
          </div>
          <div className='footer-top-right'>
            <SocialIcons list={socialIconsList} />
          </div>
        </div>
        <div className='footer-links-container footer-links-mobile'>
          <NavigationLinks {...props} />
        </div>
        <div className='footer-bottom'>
          <div className='footer-bottom-left'>
            <div className='copyright text4'>{copyright}</div>
          </div>
          <div className='footer-bottom-middle'></div>
          <div className='footer-bottom-right'></div>
        </div>
      </Container>
    </footer>
  );
}
